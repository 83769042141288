import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RAZZLE_BUILD_MODE } from '@env';
import lodashFilter from 'lodash/filter';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashPick from 'lodash/pick';

import modals from '../../../Components/Sheets/modals';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';
import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import constants from '../../../Config/constants';
import StoreHelper from '../../../Helper/Store';
import useGoBack from '../../../Hooks/useGoBack';
import { updateCheckoutDetails } from '../../../RTK/checkout';
import { checkoutOrderTypeSelector } from '../../../RTK/checkout/selectors';
import { checkout } from '../../../RTK/defaultValues';
import { reset, setType } from '../../../RTK/mealPlan';
import routeList from '../../../Routes/list';

const { ORDER_TYPES, ORDER_TYPE_DATA, ORDER_TYPE_API_KEYS, isWeb } = constants;

let isMealPlanTypeShowing = false;
function useController({ isModal = false } = {}) {
  const dispatch = useDispatch();
  const goBack = useGoBack();
  const navigation = !isModal && useNavigation();
  const route = !isModal && useRoute();
  const { showModalPrompt } = useModalPrompt();

  const shopData = useSelector((state) => state.shop?.data);
  const mealPlanData = useSelector((state) => state.mealPlan?.data);
  const whenFilter = useSelector((state) => state.filter.home.when);

  const selectedOrderType = useSelector((state) =>
    checkoutOrderTypeSelector(state, shopData?.id, true)
  );

  const isMealPlan = selectedOrderType?.value === ORDER_TYPES.MEAL_PLAN; // should only show if order_type is meal plan
  let paramMealPlanType = '';

  // Check if running in web
  if (isWeb) {
    if (RAZZLE_BUILD_MODE === 'branded') {
      const params = new URLSearchParams(document?.location?.search); // check url params for order_type
      paramMealPlanType = params.get('meal_plan_type') || paramMealPlanType; // default order type as string
    }
  }

  const storeOrderTypesAvailability = lodashPick(shopData, ORDER_TYPE_API_KEYS);

  const orderTypes = lodashFilter(
    ORDER_TYPE_DATA,
    (d) => storeOrderTypesAvailability[d.apiDataKey]
  ); // get all available order types in array
  const isMealPlanOnlyTypes =
    orderTypes.length === 1 && orderTypes?.[0]?.value === ORDER_TYPES.MEAL_PLAN;
  const itemRequired = (mealPlanData?.type || paramMealPlanType)?.substring?.(
    0,
    1
  );

  // hooks for handling when store page is refreshed or reopen the store
  // that is already meal plan selected or only has meal plan types, it should open the meal plan selector
  useEffect(() => {
    // Check if previously selected order type does not exist on current store
    if (storeOrderTypesAvailability[selectedOrderType['apiDataKey']]) {
      const currentScreen = constants.isWeb
        ? window.location.pathname.slice(1)
        : route.name;
      const noSelectedType = !mealPlanData?.type || !paramMealPlanType; // should only show if no selected type yet
      if (
        (isMealPlan || isMealPlanOnlyTypes) &&
        noSelectedType &&
        currentScreen === routeList.STORE
      ) {
        // if meal plan type and no type is selected
        _showMealPlanSelectorOnLoad(); // show meal plan type selector
      }
    } else {
      _updateShopOrderType(orderTypes[0]);
    }
  }, []);

  const _getMealPlanModalMethod = () => {
    const modalName = constants.isWeb
      ? MODALPROMPT.mealPlanTypeSelector
      : modals.MEAL_PLAN_TYPE_SELECTOR;
    const _show = constants.isWeb ? showModalPrompt : modals.show;
    const _getValue = (data) => (constants.isWeb ? data?.value : data);
    return {
      modalName,
      getValue: _getValue,
      show: _show,
    };
  };

  // for showing store status
  const _getStoreStatus = (isForAccordion) => {
    if (lodashIsEmpty(shopData)) {
      return {};
    }
    return StoreHelper.getStoreHourStatus({
      storeHours: shopData?.store_hours,
      offDates: shopData?.off_dates,
      preOrderTime: shopData?.pre_order_to_order_queue_timer,
      isForAccordion,
      dateAndTime: whenFilter?.value, // to adjust on the date filter not just current date and time
    });
  };

  // when user click the alert button to go to store additional info (timeslots, address etc)
  const _onAlertPressed = () => {
    navigation.navigate(routeList.STORE_INFORMATION);
  };

  // this called onload, if user did not select meal plan type it should go back if only meal plan else reset to first order type
  const _showMealPlanSelectorOnLoad = async () => {
    const { modalName, getValue, show } = _getMealPlanModalMethod();
    if (isMealPlanTypeShowing) {
      // if meal plan type selector is already open
      // just return dont open it again
      return;
    }
    // open meal plan type selector modal
    isMealPlanTypeShowing = true;
    const result = await show(modalName, {
      value: mealPlanData?.type || paramMealPlanType,
    });
    isMealPlanTypeShowing = false;
    const value = getValue(result);
    if (Boolean(value)) {
      // if user select
      dispatch(setType(value)); // set the meal plan type to selected
      if (!isMealPlan) {
        // if not yet meal plan, update the order type to meal plan, it can be called even the order type
        // is not meal plan if the store only has meal plan type available
        dispatch(
          updateCheckoutDetails({
            store_id: shopData?.id,
            keyToUpdate: checkout.keys.ORDER_TYPE,
            keyValue: lodashFind(ORDER_TYPE_DATA, {
              value: ORDER_TYPES.MEAL_PLAN,
            }),
          })
        );
      }
    } else {
      if (isMealPlanOnlyTypes) {
        // if types data is only 1 it means, it only has meal plan as available order types
        // otherwise it will not come to this function in the first place, so go back to previous page
        goBack();
      } else {
        // if user did not select and just close the modal
        _updateShopOrderType(null); // reset to first order type by setting the value to null
      }
    }
  };

  // when user click the see reviews
  const _onSeeReviews = () => {
    navigation.navigate(routeList.STORE_REVIEWS, {
      id: shopData?.id,
      name: shopData?.name,
    });
  };

  // when user click the order type
  const _updateShopOrderType = async (type) => {
    // if no itemRequired yet and selected meal plan
    if (!itemRequired && type?.value === ORDER_TYPES.MEAL_PLAN) {
      const { modalName, getValue, show } = _getMealPlanModalMethod();
      if (isMealPlanTypeShowing) {
        return; // if meal plan type selector is already open, just return dont open it again
      }
      isMealPlanTypeShowing = true;
      const result = await show(modalName, {
        value: mealPlanData?.type || paramMealPlanType,
      }); // open meal plan type selector
      isMealPlanTypeShowing = false;
      const value = getValue(result);
      if (Boolean(value)) {
        dispatch(setType(value));
      } else {
        return; // if did not select and order types is not just meal plan, stop the code from going
      }
    } else if (constants.isWeb) {
      // reset meal plan when selecting other order type for web
      dispatch(reset());
    }
    // if not meal plan or user select on meal plan type, update order type
    dispatch(
      updateCheckoutDetails({
        store_id: shopData?.id,
        keyToUpdate: checkout.keys.ORDER_TYPE,
        keyValue: type,
      })
    );
  };

  return {
    shopData,
    availableStoreOrderTypes: storeOrderTypesAvailability,
    selectedOrderType,
    itemRequired: itemRequired ? Number(itemRequired) : 0,
    getStoreStatus: _getStoreStatus,
    onAlertPressed: _onAlertPressed,
    onSeeReviews: _onSeeReviews,
    updateShopOrderType: _updateShopOrderType,
  };
}

export default useController;
