import React, { Fragment, useRef, useState } from 'react';
import { FlatList, ActivityIndicator, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import StyleSheet from 'react-native-media-query';
import { Divider, Icon, Text } from '@ui-kitten/components';

import lodashIsEmpty from 'lodash/isEmpty';
import lodashPick from 'lodash/pick';

import Button from '../../Button';

import AnalyticsHelper from '../../../Helper/Analytics';
import ImageHelper from '../../../Helper/Image';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';

import Image from '../../Image';

import searchApi from '../../../Service/api/search';

import useCancellableRequest from '../../../Hooks/useCancellableRequest';
import { CANCEL_ERROR } from 'apisauce';

import SearchSection from '../../../Screens/Search/SearchSection';
import RecentlyViewedSection from '../../../Screens/Search/RecentlyViewedSection';
import { pushRecentlySearch } from '../../../RTK/search';

import routeList from '../../../Routes/list';
import useFavorite from '../../../Hooks/useFavorite';
import HorizontalListing, { getMarginStyle } from '../../HorizontalListing';
import ItemSmall from '../../Card/ItemSmall';
import { capitalize, formatDistance, formatDuration } from '../../../Helper';
import Badge from '../../Badge';
import RatingText from '../../RatingText';
import { subTitleProps } from '../../Card/styles';
import useModalPrompt from '../Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../Modal/ModalPrompt/config';

import Service from '../../../Service';

const initialState = {
  isLoading: false,
  results: [],
  searchValue: '',
  noResult: false,
  error: '',
};

const Search = () => {
  const searchRef = useRef<any>();
  const containerRef = useRef<FlatList>();

  const dispatch = useDispatch();
  const navigation = useNavigation<any>();
  const route = useRoute<any>();

  const filterHomeTags = useSelector((state: any) => state.filter?.home?.tags);
  const recentlySearched = useSelector(
    (state: any) => state.search.recentlySearched
  );

  const { showModalPrompt } = useModalPrompt();
  const { createRequest } = useCancellableRequest();
  const { isSavedToFavourite, toggleFavourite, disableFavourite } =
    useFavorite();

  const [state, setState] = useState(initialState);
  const [isOpen, setOpen] = useState(false);

  const divRef = useRef(null);

  const _goToStore = (store) => () => {
    navigation.navigate(routeList.STORE, {
      id: store?.id,
      distance: store?.distance,
      duration: store?.duration,
    });
  };
  const _onStoreItemResultPressed = (storeItem) => () => {
    AnalyticsHelper.itemSelection({
      ...storeItem,
      fromSection: `Search result from '${state.searchValue}'`,
    });
    showModalPrompt(MODALPROMPT.itemModal, {
      item: {
        item_id: storeItem?.id,
        ...storeItem,
      },
      route: route,
      navigation: navigation,
    });
  };

  const _onCuisinesOrRecentlyViewedPressed = async (val, isTag = false) => {
    searchRef.current._onInputChange(val, true);
    containerRef.current.scrollToIndex({
      index: 0,
      animated: true,
      viewOffset: 0,
      viewPosition: 0,
    });
    if (!isTag) {
      dispatch(pushRecentlySearch(val));
    }
  };

  const _renderItem = ({ isFirstOfList, isLastOfList, item }) => (
    <View style={getMarginStyle(isFirstOfList, isLastOfList, false)}>
      <ItemSmall {...item} onPress={_onStoreItemResultPressed(item)} />
    </View>
  );

  const searchResultItem = ({ item }) => {
    const isSaved = isSavedToFavourite(item.id);
    const image = ImageHelper.getOrderImage(item);
    const storeName = item?.name;

    return (
      <Fragment>
        <Button style={styles.storeItemSearch} onPress={_goToStore(item)} plain>
          <View style={styles.searched_item_result_container}>
            {
              //@ts-ignore
              <Image
                source={{ uri: image }}
                style={styles.image_search_result}
                resizeMode="cover"
              />
            }
          </View>
          <View style={styles.description_search_result}>
            {/* Store name & distance */}
            <View>
              <Text category="p1" numberOfLines={1}>
                {item.name}
              </Text>

              <View style={ThemeStyle.flexDirectionRowCenter}>
                {Boolean(item?.duration) && (
                  <Icon
                    name="motorbike-electric"
                    pack="material"
                    fill={ThemeColor.green}
                    style={[styles.storeIcon, ThemeStyle.spacingRightSmall]}
                  />
                )}
                <Text {...subTitleProps}>
                  {Boolean(item?.duration) &&
                    `${formatDuration(item?.duration)} min · `}
                  {formatDistance(item?.distance)}
                </Text>
              </View>
            </View>

            {/* Icon & Delivery duration, save button and store rating */}
            <View style={ThemeStyle.alignItemsEnd}>
              <Button
                disabled={disableFavourite}
                hitSlop={{ top: 10, left: 10, bottom: 10 }}
                onPress={toggleFavourite(item)}
                plain
              >
                <Icon
                  name={isSaved ? 'heart' : 'heart-outline'}
                  fill={isSaved ? ThemeColor.green : ThemeColor.gray}
                  style={styles.storeIcon}
                />
              </Button>

              <RatingText storeId={item?.id} {...subTitleProps} />

              {/* Store tags */}
              {!lodashIsEmpty(item?.store_tags) && (
                <View
                  style={[
                    ThemeStyle.flexDirectionRowCenter,
                    ThemeStyle.spacingTopSmall,
                  ]}
                >
                  {item?.store_tags.slice(0, 3).map((tag, i) => (
                    <View
                      key={i}
                      style={[{ flexShrink: 1 }, ThemeStyle.spacingLeftSmall]}
                    >
                      <Badge
                        style={{
                          borderRadius: 20,
                          paddingHorizontal: 8,
                        }}
                        type={Badge.TYPES.SOLID}
                        numberOfLines={1}
                        adjustsFontSizeToFit
                        size={'c1'}
                      >
                        {capitalize(tag)}
                      </Badge>
                    </View>
                  ))}
                </View>
              )}
            </View>
          </View>
        </Button>

        {!lodashIsEmpty(item?.searched_items) && (
          <View style={[ThemeStyle.spacingBottomMedium]}>
            <HorizontalListing
              data={item?.searched_items}
              renderItem={({ isFirstOfList, isLastOfList, item }) =>
                _renderItem({
                  isFirstOfList,
                  isLastOfList,
                  item: {
                    ...item,
                    storeName: storeName,
                    storeImage: image,
                  },
                })
              }
              arrowStyles={{ transform: [{ translateY: 0 }] }}
              arrowContentStyles={{ width: 30, height: 30 }}
              arrowIcon={{ width: 20, height: 20 }}
            />
          </View>
        )}
      </Fragment>
    );
  };

  const onChangeText = (txt) => {
    if (txt.trim().length !== 0) {
      _apiCallForSearchContain(txt);
    } else {
      setState({
        ...state,
        searchValue: txt,
        isLoading: false,
        results: [],
        noResult: false,
        error: '',
      });
    }
  };

  const _apiCallForSearchContain = async (searchValue) => {
    setState({
      ...state,
      isLoading: true,
    });

    const distance = filterHomeTags.find(
      (item) => item.paramName === 'distance'
    );

    const { ok, data, problem } = await createRequest(
      searchApi.getSearchResults,
      searchValue,
      `&distance=${distance.values[0].value}&open_only=true`,
      false
    );

    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }

    if (ok) {
      setState({
        ...state,
        searchValue,
        isLoading: false,
        results: ok ? data.result : [],
        noResult: !ok || data.result.length === 0,
        error: problem,
      });

      dispatch(pushRecentlySearch(searchValue));
    } else {
      setState({
        ...state,
        isLoading: true,
      });
    }
  };

  const _open = () => {
    setOpen(true);
  };
  const _onClose = () => {
    setOpen(false);
    setState({ ...state, results: [] });
  };

  const _onLayout = (e) => {
    if (e.nativeEvent.layout.width === 0) {
      _onClose();
    }
  };

  return (
    <Fragment>
      <View
        ref={divRef}
        style={{
          zIndex: 999999999999999,
        }}
        onLayout={_onLayout}
      >
        <SearchSection
          onRef={searchRef}
          onSearchChange={onChangeText}
          onFocus={_open}
          placeholder="Search store, dishes, products"
          inputStyle={[ThemeStyle.pageBackground, styles.searchInput]}
        />

        {(!lodashIsEmpty(recentlySearched) || !lodashIsEmpty(state.results)) &&
          isOpen && (
            <View style={styles.contentContainer}>
              <FlatList
                ref={containerRef}
                dataSet={{ media: ids.searchContainer }}
                style={styles.searchContainer}
                data={state.results}
                contentContainerStyle={styles.flatlist_search_container}
                renderItem={searchResultItem}
                ListHeaderComponentStyle={{
                  marginHorizontal: -10,
                }}
                ListHeaderComponent={
                  lodashIsEmpty(state.results) &&
                  !state.noResult && (
                    <View style={{ paddingHorizontal: 20 }}>
                      <RecentlyViewedSection
                        onPress={_onCuisinesOrRecentlyViewedPressed}
                      />
                    </View>
                  )
                }
                ItemSeparatorComponent={() => (
                  <Divider
                    style={[ThemeStyle.divider, { marginHorizontal: 20 }]}
                  />
                )}
                ListEmptyComponent={
                  state.noResult && (
                    <View
                      style={[
                        ThemeStyle.alignItemsCenter,
                        ThemeStyle.justifyContentCenter,
                        { height: 100 },
                      ]}
                    >
                      <Text category="s1">
                        {!lodashIsEmpty(state.error)
                          ? 'An error has occurred'
                          : 'No results found.'}
                      </Text>
                      <Text category="p2" status="primary">
                        {!lodashIsEmpty(state.error)
                          ? Service.handleErrorMessage(state.error)
                          : "We couldn't find what you're looking for"}
                      </Text>
                    </View>
                  )
                }
              />

              {state.isLoading && (
                <View style={styles.loaderWrapper}>
                  <ActivityIndicator size="large" color={ThemeColor.green} />
                </View>
              )}
            </View>
          )}
      </View>

      {isOpen && <div style={styles.overlay} onClick={_onClose} on />}
    </Fragment>
  );
};

const { ids, styles } = StyleSheet.create({
  searchContainer: {
    ...ThemeStyle.flex1,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99999,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  searchInput: {
    borderColor: ThemeColor.gray,
    borderRadius: 30,
    borderWidth: 1,
    minWidth: 200,
  },

  popoverContentWrapper: {
    ...ThemeStyle.flex1,
    backgroundColor: 'transparent',
  },
  contentContainer: {
    top: 70,
    left: 20,
    right: 20,
    width: 'auto',
    padding: 10,
    position: 'absolute',
    backgroundColor: ThemeColor.white,
    borderColor: ThemeColor.lightGray,
    ...ThemeStyle.shadow,
    borderWidth: 1,
    borderRadius: 10,
  },
  storeItemSearch: {
    ...ThemeStyle.flex1,
    height: 60,
    paddingHorizontal: 10,
    paddingVertical: 15,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginHorizontal: 15,
  },
  image_search_result: {
    width: 60,
    height: 60,
    borderRadius: 30,
  },
  flatlist_search_container: {
    maxHeight: 600,
  },
  searched_item_result_container: {
    width: 60,
    height: 60,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: ThemeColor.gray,
    aspectRatio: 0.1,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  description_search_result: {
    marginLeft: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    flex: 1,
  },
  loaderWrapper: {
    ...ThemeStyle.alignItemsCenter,
    ...ThemeStyle.justifyContentCenter,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  storeIcon: {
    width: 20,
    height: 20,
  },
});

export default React.memo(Search);
